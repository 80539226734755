import PropTypes from "prop-types"
import React, { Component } from "react"
import { Location } from "@reach/router"
import { Link } from "gatsby"
import { useIntl, IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

const languageName = {
  "zh-hk": "繁體",
  "zh-cn": "简体",
  en: "Eng",
}

const isBrowser = () => typeof window !== "undefined"

// function to return path without language code
const trimPath = () => {
  const path = isBrowser() ? window.location.pathname : "/"
  if (
    path.search("/posts/") !== -1 ||
    path.search("/posts-cn/") !== -1 ||
    path.search("/posts-en/") !== -1 ||
    path.search("_cases") !== -1
  )
    // language specific routes, simply navigate to home
    return "/"
  else
    return path
      .replace("/zh-hk/", "/")
      .replace("/zh-cn/", "/")
      .replace("/en/", "/")
}

const LanguageSwitcher = () => {
  const intl = useIntl()
  const trimmedPath = trimPath()
  switch (intl.locale) {
    case "en":
      return (
        <span>
          <Link to={`${trimmedPath}`}>{languageName["zh-hk"]}</Link>
          &nbsp;&nbsp;
          <Link to={`/zh-cn${trimmedPath}`}>{languageName["zh-cn"]}</Link>
        </span>
      )
    case "zh-cn":
      return (
        <span>
          <Link to={`/en${trimmedPath}`}>{languageName["en"]}</Link>
          &nbsp;&nbsp;
          <Link to={`${trimmedPath}`}>{languageName["zh-hk"]}</Link>
        </span>
      )
    default:
      return (
        <span>
          <Link to={`/en${trimmedPath}`}>{languageName["en"]}</Link>
          &nbsp;&nbsp;
          <Link to={`/zh-cn${trimmedPath}`}>{languageName["zh-cn"]}</Link>
        </span>
      )
  }
  /*return (
    <IntlContextConsumer>
      {({ languages, language: currentLocale }) =>
        languages.map(language => {
          if (language != currentLocale) {
            return (
              <Link
                key={language}
                onClick={() => changeLocale(language)}
                as="button"
              >
                {languageName[language]}
              </Link>
            )
          }
        })
      }
    </IntlContextConsumer>
  )*/
}
/*
function LanguageSelector() {
  const intl = useIntl()
  switch (intl.locale) {
  }
  if (lang === "default") {
    return (
      <Link className={className} to={`/cs/${location.pathname}`}>
        čeština
      </Link>
    )
  } else {
    return (
      <Link
        className={className}
        to={location.pathname.replace("/" + lang + "/", "/")}
      >
        english
      </Link>
    )
  }
}*/
export default LanguageSwitcher
