import React, { useState } from "react"
//import { Link } from "gatsby"
import { RiMenu3Line, RiCloseLine } from "react-icons/ri"
import Img from "gatsby-image"
import Button from "react-bootstrap/Button"
import { Navbar, Nav, NavDropdown } from "react-bootstrap"
import { Container, Row, Col } from "react-bootstrap"
import { css } from "@emotion/core"
import {
  useIntl,
  Link,
  FormattedMessage,
  changeLocale,
} from "gatsby-plugin-intl"
import LanguageSwitcher from "./language-switcher"
import Logo from "./logo"

const styles = {
  row: {
    marginLeft: 0,
    marginRight: 0,
    minWidth: "250px",
    height: "100%",
  },
}

const NavigationItems = ({ title, s2 }) => {
  const intl = useIntl()
  const locale = intl.locale
  const tt = key => {
    return intl.formatMessage({ id: key })
  }
  return (
    <Row className="nav-row">
      <Navbar className="justify-content-end" expand="lg">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <NavDropdown title={tt("about")}>
              <NavDropdown.Item as="li">
                <Link to="/about">{tt("history")}</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="li">
                <Link to="/jha">{tt("jha")}</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="li">
                <Link to="/publications">{tt("publications")}</Link>
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={tt("nsp")}>
              <NavDropdown.Item as="li">
                <Link to="/nsp">{tt("nspIntro")}</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="li">
                <Link to="/nsp#cases">{tt("exampleCases")}</Link>
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={tt("serviceAndTeam")}>
              <NavDropdown.Item as="li">
                <Link to="/therapy">{tt("therapy")}</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="li">
                <Link to="/np">- {tt("np")}</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="li">
                <Link to="/counselling">- {tt("therapy_psy")}</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="li">
                <Link to="/speechtherapy">- {tt("SpeechPathology")}</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="li">
                <Link to="/team">{tt("team")}</Link>
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={tt("syndromesAndTherapy")}>
              <NavDropdown.Item as="li">
                <Link to="/autism">{tt("Autism")}</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="li">
                <Link to="/aspergers">{tt("Aspergers")}</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="li">
                <Link to="/dyslexia">{tt("Dyslexia")}</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="li">
                <Link to="/devdyslexia">{tt("DevDyslexia")}</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="li">
                <Link to="/devdelays">{tt("DevDelays")}</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="li">
                <Link to="/adhd">{tt("ADHD")}</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="li">
                <Link to="/convoicedisorder">{tt("ConVoiceDisorder")}</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="li">
                <Link to="/puberphonia">{tt("Puberphonia")}</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="li">
                <Link to="/aphasia">{tt("Aphasia")}</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="li">
                <Link to="/swallowingdifficulty">
                  {tt("SwallowingDifficulty")}
                </Link>
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={tt("mainland")}>
              <NavDropdown.Item as="li">
                <Link to="/mainland#mainland_news">{tt("mainlandNews")}</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="li">
                <Link to="/mainland#beijing">{tt("beijing")}</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="li">
                <Link to="/mainland#foshan">{tt("foshan")}</Link>
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={tt("recommend")}>
              <NavDropdown.Item as="li">
                <Link to="/keyevent">{tt("quotes")}</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="li">
                <Link to="/products">{tt("product")}</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="li">
                <Link to="/plans">{tt("plan")}</Link>
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={tt("cases")}>
              <NavDropdown.Item as="li">
                {locale === "zh-hk" && <Link to="/nsp_cases">{tt("nsp")}</Link>}
                {locale === "zh-cn" && (
                  <Link to="/nsp_cases_cn">{tt("nsp")}</Link>
                )}
                {locale === "en" && <Link to="/nsp_cases_en">{tt("nsp")}</Link>}
              </NavDropdown.Item>
              <NavDropdown.Item as="li">
                {locale === "zh-hk" && (
                  <Link to="/adult_cases">{tt("adult")}</Link>
                )}
                {locale === "zh-cn" && (
                  <Link to="/adult_cases_cn">{tt("adult")}</Link>
                )}
                {locale === "en" && (
                  <Link to="/adult_cases_en">{tt("adult")}</Link>
                )}
              </NavDropdown.Item>
              <NavDropdown.Item as="li">
                {locale === "zh-hk" && (
                  <Link to="/mainland_cases">{tt("mainland")}</Link>
                )}
                {locale === "zh-cn" && (
                  <Link to="/mainland_cases_cn">{tt("mainland")}</Link>
                )}
                {locale === "en" && (
                  <Link to="/mainland_cases_en">{tt("mainland")}</Link>
                )}
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as="li">
              <Link to="/#contact">{tt("contact")}</Link>
            </Nav.Link>
            <Nav.Link as="li" className="d-lg-none">
              <span style={{ color: "var(--nav-color)" }}>
                {tt("Language")}
              </span>
              <LanguageSwitcher />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Row>
  )
}

const Navigation = ({ title, s1, s2 }) => {
  const intl = useIntl()
  const locale = intl.locale
  const tt = key => {
    return intl.formatMessage({ id: key })
  }
  return (
    <>
      <div className="navEx">
        <Container>
          <Row style={styles.row}>
            <Col className="col-title">
              <div className="logo">
                <Link to="/">
                  <Img
                    fluid={s1.childImageSharp.fluid}
                    style={{ maxHeight: "100%" }}
                    imgStyle={{ objectFit: "contain" }}
                  />
                </Link>
              </div>
            </Col>
            <Col className="px-0">
              <Row style={styles.row}>
                <Col className="title">{tt("clinicName")}</Col>
                <Col className="langswitcher d-none d-lg-block text-right">
                  <LanguageSwitcher />
                </Col>
              </Row>
              <Row className="col-menu">
                <NavigationItems title={title} s2={s2} />
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Navigation
