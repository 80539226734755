import React from "react"
import ReactDOM from "react-dom"
import { useStaticQuery, graphql } from "gatsby"

import Navigation from "./navigation_2"
//import Navigation from "./navigation"

import "../assets/scss/style.scss"
import Footer from "./footer"

const query = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        siteTitle: title
      }
    }
    s1: file(relativePath: { eq: "MainLogo-03.png" }) {
      childImageSharp {
        fluid(maxHeight: 100, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    s2: file(relativePath: { eq: "earth.png" }) {
      childImageSharp {
        fluid(maxHeight: 100, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const ZoomIcon = () => {
  return (
    <div className="floating-icon">
      <svg
        viewBox="0 0 1329.08 1329.08"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        imageRendering="optimizeQuality"
        fillRule="evenodd"
        clipRule="evenodd"
      >
        <g id="Layer_x0020_1">
          <g id="_2116467169744">
            <path
              d="M664.54 0c367.02 0 664.54 297.52 664.54 664.54s-297.52 664.54-664.54 664.54S0 1031.56 0 664.54 297.52 0 664.54 0z"
              fill="#e5e5e4"
              fillRule="nonzero"
            />
            <path
              d="M664.54 12.94c359.87 0 651.6 291.73 651.6 651.6s-291.73 651.6-651.6 651.6-651.6-291.73-651.6-651.6 291.74-651.6 651.6-651.6z"
              fill="#F3DFE3"
              fillRule="nonzero"
            />
            <path
              d="M664.54 65.21c331 0 599.33 268.33 599.33 599.33 0 331-268.33 599.33-599.33 599.33-331 0-599.33-268.33-599.33-599.33 0-331 268.33-599.33 599.33-599.33z"
              fill="#4a8cff"
              fillRule="nonzero"
            />
            <path
              d="M273.53 476.77v281.65c.25 63.69 52.27 114.95 115.71 114.69h410.55c11.67 0 21.06-9.39 21.06-20.81V570.65c-.25-63.69-52.27-114.95-115.7-114.69H294.6c-11.67 0-21.06 9.39-21.06 20.81zm573.45 109.87l169.5-123.82c14.72-12.18 26.13-9.14 26.13 12.94v377.56c0 25.12-13.96 22.08-26.13 12.94l-169.5-123.57V586.64z"
              fill="#fff"
              fillRule="nonzero"
            />
          </g>
        </g>
      </svg>
    </div>
  )
}

const Layout = ({ children, className }) => {
  const { site, s1, s2 } = useStaticQuery(query)
  const { siteTitle } = site.siteMetadata

  return (
    <div className="primary-container">
      <Navigation title={siteTitle} s1={s1} s2={s2} />
      <main className="main-container">{children}</main>
      <Footer />
      {/*<ZoomIcon />*/}
    </div>
  )
}

export default Layout
