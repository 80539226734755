//import React from "react"
import { graphql, Link } from "gatsby"
//import { useIntl } from "gatsby-plugin-intl"
import { RiHeart2Line } from "react-icons/ri"
import { css } from "@emotion/core"
import React, { useState } from "react"
import { useIntl, FormattedMessage, changeLocale } from "gatsby-plugin-intl"

/*
export const pageQuery = graphql`
  query FooterQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        hk1
        hk2
        cn1
        cn2
        en1
        en2
      }
    }
  }
`*/

const Footer = () => {
  const intl = useIntl()
  const locale = intl.locale
  const tt = key => {
    return intl.formatMessage({ id: key })
  }
  return (
    <footer className="site-footer">
      <div className="container px-1">
        {tt("footer1")}
        <br />
        {tt("footer2")}
        <br />
        {tt("footer3")}
      </div>
    </footer>
  )
}

export default Footer
