import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

const SEO = ({ title, description, image, article }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)
  const intl = useIntl()
  const tt = key => {
    if (intl) {
      if (intl.messages[key] === undefined) {
        return key
      } else if (intl.messages[key] === "") {
        return ""
      } else {
        return intl.formatMessage({ id: key })
      }
    } else {
      return key
    }
  }

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }

  const getLang = () => {
    switch (intl.locale) {
      case "en":
        return { lang: "en-US", a1: "en-us", a2: "en" }
      case "gb":
        return { lang: "zh-Hans", a1: "zh-hans", a2: "zh" }
      default:
        return { lang: "zh-Hant", a1: "zh-hant", a2: "zh" }
    }
  }
  const lang = getLang()

  const ldjson1 = JSON.stringify({
    "@context": "http://schema.org",
    "@type": "WebSite",
    "@id": "#website",
    url: siteUrl,
    name:
      "\u590F\u5C0F\u6708\u8A00\u8A9E\u75C5\u7406\u5B78(\u8A00\u8A9E\u6CBB\u7642)\u8A3A\u6240",
    alternateName: "Joanne Ha Speech Pathology Clinic",
  }).replace(/\//g, "\\/")

  const ldjson2 = JSON.stringify({
    "@context": "http://schema.org",
    "@type": "Organization",
    url: seo.url ? seo.url : siteUrl,
    sameAs: ["https://www.facebook.com/JoanneHaSpeech/"],
    "@id": "#organization",
    name:
      "\u590F\u5C0F\u6708\u8A00\u8A9E\u75C5\u7406\u5B78(\u8A00\u8A9E\u6CBB\u7642)\u8A3A\u6240",
    logo: seo.image,
  }).replace(/\//g, "\\/")

  const ttTitle = tt(seo.title) + " | " + tt("seo.title.main")
  const ttDesc = tt(seo.description)

  return (
    <Helmet title={ttTitle} titleTemplate={titleTemplate} defer={false}>
      <html lang={lang.lang} />
      <link rel="alternate" href={seo.url} hreflang={lang.a1} />
      <link rel="alternate" href={seo.url} hreflang={lang.a2} />
      {/* <link rel="alternate" href={seo.url} hreflang="x-default" /> */}
      <meta name="description" content={ttDesc} />
      <meta name="image" content={seo.image} />

      {seo.url && <meta property="og:url" content={seo.url} />}

      {(article ? true : null) && <meta property="og:type" content="article" />}

      {ttTitle && <meta property="og:title" content={ttTitle} />}

      {ttDesc && <meta property="og:description" content={ttDesc} />}

      {seo.image && <meta property="og:image" content={seo.image} />}

      <meta name="twitter:card" content="summary_large_image" />

      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}

      {ttTitle && <meta name="twitter:title" content={ttTitle} />}

      {ttDesc && <meta name="twitter:description" content={ttDesc} />}

      {seo.image && <meta name="twitter:image" content={seo.image} />}

      <script type="application/ld+json">{ldjson1}</script>

      <script type="application/ld+json">{ldjson2}</script>
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: siteUrl
        defaultImage: image
        twitterUsername
      }
    }
  }
`
